<template>
  <AuthenticationContainer
    class="password-recovery"
    :backgroundType="backgroundType"
  >
    <div v-if="!isRecoveredPassword">
      <div class="password-recovery-header">
        <h2>{{ $t.authentication.password_recovery.title }}</h2>
        <p>{{ $t.authentication.password_recovery.subtitle }}</p>
      </div>
      <div>
        <v-card-text>
          <v-form @submit.prevent="passwordRecovery">
            <v-text-field
              outlined
              :color="'#3CA897'"
              :label="$t.authentication.password_recovery.labelEmail"
              :name="$t.authentication.password_recovery.labelEmail"
              :error="inputValidator()[0]"
              :error-messages="inputValidator()[1]"
              v-model.lazy="email"
              @blur="inputValidator()"
              :validate-on-blur="true"
              required
              type="email"
            ></v-text-field>
            <v-layout justify-center>
              <v-card-actions class="center">
                <v-btn
                  class="btn-submit-form"
                  type="submit"
                  color="#3CA897"
                  :disabled="!validForm"
                  >{{
                    $t.authentication.password_recovery.labelButtonLogin
                  }}</v-btn
                >
              </v-card-actions>
            </v-layout>
            <p @click="goToLogin()" class="label-recovery-back">
              {{ $t.authentication.password_recovery.labelButtonBack }}
            </p>
          </v-form>
        </v-card-text>
      </div>
    </div>
    <div class="recovered-password" v-if="isRecoveredPassword">
      <v-img class="recovered-password-img" src="@/assets/mail.svg"></v-img>
      <h2>{{ $t.authentication.recovered_password.title }}</h2>
      <p>{{ $t.authentication.recovered_password.subtitle }}</p>
      <p @click="goToLogin()" class="label-recovery-back">
        {{ $t.authentication.recovered_password.labelButtonBack }}
      </p>
    </div>
  </AuthenticationContainer>
</template>

<script>
  import { isEmpty } from "lodash";
  import { mapActions } from "vuex";

  import AuthenticationContainer from "@/modules/account/authentication/views/components/AuthenticationContainer";
  import emailValidator from "@/modules/core/helpers/emailValidator";

  export default {
    name: "PasswordRecovery",

    data: () => ({
      email: undefined,
      backgroundType: "password-recovery",
      isRecoveredPassword: false
    }),

    computed: {
      validForm() {
        return !isEmpty(this.email) && emailValidator.test(this.email);
      }
    },

    components: {
      AuthenticationContainer
    },

    methods: {
      ...mapActions("authentication", ["forgotPassword"]),
      ...mapActions(["toggleSnackbar"]),

      async passwordRecovery() {
        const payload = {
          username: this.email.trim()
        };

        try {
          const { data } = await this.forgotPassword(payload);
          this.isRecoveredPassword = true;
        } catch (err) {
          this.toggleSnackbar({
            text: this.$t.authentication.login.authentication_failure,
            type: "error"
          });
        }
      },

      goToLogin() {
        this.$router.push({ name: "login" });
      },

      inputValidator() {
        if (!this.validForm && this.email !== undefined) {
          return [true, this.$t.root.errorsForm.invalidEmail];
        }
        return [false, ""];
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .password-recovery {
    .recovered-password {
      padding: 50px 0 30px 0;

      .recovered-password-img {
        width: 170px;
        margin: auto;
        margin-bottom: 30px;
      }
    }

    .password-recovery-header,
    .recovered-password {
      h2 {
        color: $black-1;
        font-weight: 400;
        text-align: center;
        font-size: 24px;
      }
      p {
        margin-top: 10px;
        color: $black-3;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .label-recovery-back {
      margin: 30px 0 -20px 0 !important;
      color: $black-2 !important;
      font-size: 14px !important;
      font-weight: bold !important;
      text-align: center;
      cursor: pointer;
    }

    ::v-deep .v-card__actions {
      width: 100%;
      padding: 0%;

      .btn-submit-form {
        height: 56px;
        width: 100%;
        border-radius: 5px !important;
        color: $white-1;
      }
    }
  }
</style>
