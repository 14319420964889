<template>
  <AuthenticationContainer
    class="new-password"
    :backgroundType="backgroundType"
  >
    <div class="new-password-header">
      <v-img
        class="logo-img-new-password"
        src="@/assets/logotipo-programando-futuro-horizontal.svg"
      ></v-img>
      <v-spacer></v-spacer>
      <p class="label-new-password">
        {{ $route.meta.subtitle }}
      </p>
    </div>
    <v-card-text>
      <v-form @submit.prevent="newPassword">
        <v-text-field
          outlined
          id="password"
          :color="'#3CA897'"
          :label="$t.authentication.new_password.labelPassword"
          :name="$t.authentication.new_password.labelPassword"
          :error="inputValidatorPassword[0]"
          :error-messages="inputValidatorPassword[1]"
          v-model.lazy="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          outlined
          id="confirmPassword"
          :color="'#3CA897'"
          :label="$t.authentication.new_password.labelConfirmPassword"
          :name="$t.authentication.new_password.labelConfirmPassword"
          :error="inputValidatorConfirmPassword[0]"
          :error-messages="inputValidatorConfirmPassword[1]"
          v-model.lazy="confirmPassword"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showConfirmPassword ? 'text' : 'password'"
          @click:append="showConfirmPassword = !showConfirmPassword"
        ></v-text-field>
        <v-layout justify-center>
          <v-card-actions class="center">
            <v-btn
              class="btn-submit-form"
              type="submit"
              color="#3CA897"
              :disabled="!validForm"
              >{{ $t.authentication.new_password.labelButton }}</v-btn
            >
          </v-card-actions>
        </v-layout>
      </v-form>
    </v-card-text>
  </AuthenticationContainer>
</template>

<script>
  import { mapActions } from "vuex";

  import AuthenticationContainer from "@/modules/account/authentication/views/components/AuthenticationContainer";
  import passwordValidator from "@/modules/core/helpers/passwordValidator";

  export default {
    name: "NewRecovery",

    data: () => ({
      backgroundType: "new-password",
      password: undefined,
      showPassword: false,
      confirmPassword: undefined,
      showConfirmPassword: false,
      token: undefined
    }),

    computed: {
      inputValidatorPassword() {
        if (
          !passwordValidator.test(this.password) &&
          this.password !== undefined
        ) {
          return [
            true,
            this.$t.authentication.new_password.errorInvalidPassword
          ];
        }
        return [false, ""];
      },

      inputValidatorConfirmPassword() {
        if (
          !passwordValidator.test(this.confirmPassword) &&
          this.confirmPassword !== undefined
        ) {
          return [
            true,
            this.$t.authentication.new_password.errorInvalidPassword
          ];
        }

        if (
          this.password !== this.confirmPassword &&
          this.confirmPassword !== undefined
        ) {
          return [
            true,
            this.$t.authentication.new_password.errorDifferentPasswords
          ];
        }
        return [false, ""];
      },

      validForm() {
        return (
          !this.inputValidatorConfirmPassword[0] &&
          !this.inputValidatorPassword[0]
        );
      }
    },

    components: {
      AuthenticationContainer
    },

    mounted() {
      this.token = this.$route.params.token;
    },

    methods: {
      ...mapActions("authentication", ["resetPassword", "confirmAccount"]),
      ...mapActions(["toggleSnackbar"]),

      async newPassword() {
        const payload = {
          token: this.token,
          password: this.password
        };

        try {
          let response;
          if (this.$route.name === "completeRegistration") {
            response = await this.confirmAccount(payload);
          } else {
            response = await this.resetPassword(payload);
          }
          const { message } = response;
          this.$router.push({ name: "login" });
          this.toggleSnackbar({
            text: message,
            type: "success"
          });
        } catch (err) {
          this.toggleSnackbar({
            text: this.$t.authentication.login.authentication_failure,
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .new-password {
    .new-password-header {
      .logo-img-new-password {
        max-width: 291px;
        margin: auto;
      }

      .label-new-password {
        margin-top: 10px;
        color: $black-3;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }

    ::v-deep .v-card__actions {
      width: 100%;
      padding: 0%;

      .btn-submit-form {
        height: 56px;
        width: 100%;
        border-radius: 5px !important;
        color: $white-1;
      }
    }
  }
</style>
