<template>
  <AuthenticationContainer class="login" :backgroundType="backgroundType">
    <v-img
      class="logo-img-login"
      src="@/assets/logotipo-programando-futuro-horizontal.svg"
    ></v-img>
    <v-spacer></v-spacer>
    <h3 class="label-login">Entrar</h3>
    <v-card-text>
      <validation-observer>
        <v-form @submit.prevent="signIn" slot-scope="{ invalid }">
          <field
            v-model="values.username"
            type="genericText"
            label="Usuário"
            name="username"
            :isRequired="true"
            :ignoreRequiredLabel="true"
          />

          <field
            type="password"
            name="password"
            label="Senha"
            v-model="values.password"
            :isRequired="true"
            :ignoreRequiredLabel="true"
          />
          <v-layout justify-center>
            <v-card-actions class="center">
              <v-btn
                class="btn-submit-form"
                type="submit"
                :disabled="invalid"
                color="secondary-color-1"
                >Continuar</v-btn
              >
            </v-card-actions>
          </v-layout>
          <p @click="goToPasswordRecovery()" class="label-recovery-password">
            Esqueceu sua senha?
          </p>
        </v-form>
      </validation-observer>
    </v-card-text>
  </AuthenticationContainer>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";
  import { firstPageByUser } from "@/modules/core/enums/profiles.enum";
  import passwordValidator from "@/modules/core/helpers/passwordValidator";
  import AuthenticationContainer from "@/modules/account/authentication/views/components/AuthenticationContainer";

  export default {
    name: "Login",

    data: () => ({
      isUsernameInvalid: null,
      values: {
        username: "",
        password: ""
      },
      errors: {
        username: "",
        password: ""
      },
      backgroundType: "login",
      showPassword: false
    }),

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId"
      })
    },

    components: {
      ValidationObserver,
      AuthenticationContainer,
      Field
    },

    methods: {
      ...mapActions(["toggleSnackbar", "initializeRoutes"]),
      ...mapActions("user", ["setUser", "selectCRC"]),
      ...mapActions("authentication", ["login", "setToken"]),

      async signIn() {
        const payload = {
          username: this.values.username.trim(),
          password: this.values.password
        };

        if (!passwordValidator.test(payload.password)) {
          this.toggleSnackbar({
            text:
              "Não foi possível entrar. Por favor, verifique suas credenciais de acesso novamente",
            type: "error"
          });
          return;
        }

        try {
          const { data } = await this.login(payload);
          const { token } = data;
          const { user } = await this.setToken(token);

          this.setUser(user);

          const crcs = user.userRoles.filter(el => el.crc);

          if (crcs[0]) {
            let response = await this.selectCRC(crcs[0].crc.id);
            response = await this.setToken(response.data.token);
            this.setUser(response.user);
          }

          this.initializeRoutes();

          this.$router.push({
            name: firstPageByUser[this.userRoleId]
          });
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              "Não foi possível entrar. Por favor, verifique suas credenciais de acesso novamente",
            type: "error"
          });
        }
      },

      goToPasswordRecovery() {
        this.$router.push({ name: "recoveryPassword" });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .login {
    .logo-img-login {
      max-width: 291px;
      margin: auto;
    }

    .label-login {
      color: $black-1;
      font-size: 24px;
      text-align: center;
    }

    .label-recovery-password {
      margin: 30px 0 -20px 0;
      color: $black-2;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }

    ::v-deep .v-card__actions {
      width: 100%;
      padding: 0%;

      .btn-submit-form {
        height: 56px;
        width: 100%;
        border-radius: 5px !important;
        color: $white-1;
      }
    }
  }
</style>
