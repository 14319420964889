/***
 * Verifica se o valor possui:
 * - 8 caracteres
 * - Caracteres minísculos ou maiúsculos
 * - Números e caracteres especiais
 */

const passwordValidator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

export default passwordValidator;
