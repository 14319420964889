<template>
  <v-app class="background" :class="backgroundStyles(backgroundType)">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-3 pa-10 card-authentication">
              <slot></slot>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: "AuthenticationContainer",

    props: {
      backgroundType: {
        type: String,
        validator: val =>
          ["login", "password-recovery", "new-password"].includes(val)
      }
    },

    methods: {
      backgroundStyles(image) {
        const classesBackgroundStyles = {
          login: "background-login",
          "password-recovery": "background-password-recovery",
          "new-password": "background-new-password"
        };
        return classesBackgroundStyles[image];
      }
    }
  };
</script>

<style lang="scss">
  @import "@/assets/stylesheets/styleguide";

  .card-authentication {
    min-width: 400px !important;
    max-width: 480px !important;
    border-radius: 6px !important;
    margin: auto !important;
  }

  .background {
    background-color: #f4f4f4 !important;
    background-position: center center !important;
    background-clip: border-box !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .background-login {
    background-image: url("../../../../../assets/Login3.svg") !important;
  }

  .background-password-recovery {
    background-image: url("../../../../../assets/Recuperar_senha.svg") !important;
  }

  .background-new-password {
    background-image: url("../../../../../assets/Novousuario.svg") !important;
  }
</style>
